<template>
    <div>
        <div class="columns is-mobile is-multiline">
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Title') }}</label>
                    <div class="control">
                        <input class="input" type="text" v-model="alerts.name"
                               disabled>
                    </div>
                </div>
            </div>

            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Description') }}</label>
                    <div class="control">
                        <input class="input" type="text" v-model="alerts.description"
                               disabled>
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Link') }}</label>
                    <div class="control">
                        <input class="input" type="text" v-model="alerts.link"
                               disabled>
                    </div>
                </div>
            </div>
        </div>
            <div class="columns is-mobile is-multiline">
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Type') }}</label>
                    <div class="control">
                        <input class="input" type="text" v-model="alerts.type"
                               disabled>
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('User Group') }}</label>
                    <div class="control">
                        <input class="input" type="text" v-model="alerts.user_group"
                               disabled>
                    </div>
                </div>
            </div>
                <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('End date') }}</label>
                    <div class="control">
                        <input class="input" type="text" v-model="alerts.end_date"
                               disabled>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Show',
    inject: ['http', 'route', 'i18n'],
    data() {
        return {
            alerts: {
                name: '',
                description: '',
                link: '',
                type: '',
                user_group: '',
            },
        };
    },
    mounted() {
        this.fetchShow();
    },
    methods: {
        fetchShow() {
            this.http
                .get(this.route('alerts.show', { alerts: this.$route.params.alerts }))
                .then(({ data }) => {
                    this.alerts = data.alerts;
                    this.alerts.type = this.i18n(this.alerts.type);
                })
                .catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
